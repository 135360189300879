import request from '@/plugins/axios'

export const apiOperationLists = (params: any): Promise<any> =>
  request.get('/card.CouponOperation/lists', {params})

export const apiOperationDetails = (params: any): Promise<any> =>
  request.get('/card.CouponOperation/details', {params})

// //获取可作废券
// export const apiVoidedVoucherTable = (params: any): Promise<any> =>
//   request.get('/card.CouponOperation/voidedVoucherTable', {params})

export const apiVoidedVoucherTable = (params: any) => request.post('/card.CouponOperation/voidedVoucherTable', params)

export const apiCreateCouponOpt = (params: any) => request.post('/card.CouponOperation/createCouponOpt', params)


export const apiCouponOptExamine = (params: any) => request.post('/card.CouponOperation/approval', params)

