















































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { State } from "vuex-class";
import { RequestPaging, timeFormat } from "@/utils/util";
import { apiGetCustomerList } from "@/api/shop";
import {
    apiCreateCouponOpt,
    apiOperationDetails,
    apiVoidedVoucherTable,
} from "@/api/card/card_operation";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
@Component({
    components: {
        lsPagination,
        ExportData,
        LsDialog,
    },
})
export default class productFileAdd extends Vue {
    @State("user") user: any;

    get userInfo() {
        return this.user.userInfo;
    }
    pager: RequestPaging = new RequestPaging();
    form: Object = {
        creat_time: "",
        customer_id: "",
        operation_type: 5,
        operation_ethod: 3,
        invalid_scope: 1,
        validity_period: "",
        recharge_type: 1,
        recharge_count: "",
        reason: "",
        optList: [],
        admin_name: "",
    };
    id: any;
    disabled: any = false;
    voidedVoucherTable: any[] = [];
    custom_list: any[] = [];
    auditing_log: any[] = [];

    /** S Methods **/
    // 列获取作废列表
    getVoidedVoucherTable(page?: number): void {
        page && (this.pager.page = page);
        this.pager.request({
            callback: apiVoidedVoucherTable,
            params: {
                ...this.form,
            },
        });
    }
    async getCustomerList() {
        const custom_list_array = await apiGetCustomerList({
            page_type: 0,
            type: 0,
        });
        this.custom_list = custom_list_array.lists;
    }
    addLine() {
        this.form.optList.push({
            batch_id: "",
            startVoucherNo: "",
            endVoucherNo: "",
            voucherNo: "",
        });
    }
    delLine(index: number) {
        this.form.optList = this.form.optList.filter(
            (element: any, indexs: number) => indexs !== index
        );
    }
    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate(async (valid: boolean): any => {
            if (!valid) return;
            const res = await apiCreateCouponOpt(this.form);
            this.$router.go(-1);
            // await this.$router.push({
            //   path: '/card/makeCard/cardOperation',
            // })
        });
    }
    async getDetails() {
        const details = await apiOperationDetails({ id: this.id });
        this.form = details.info;
        this.auditing_log = details.log;
    }
    created() {
        this.id = this.$route.query.id;
        this.getCustomerList();
        if (this.id == null || this.id == "") {
            this.form.creat_time = timeFormat(
                new Date().getTime(),
                "yyyy-mm-dd hh:MM:ss"
            );
        } else {
            this.disabled = true;
            this.getDetails();
        }
    }
}
